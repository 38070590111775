<template>
    <div>
        <Header />
        <p class="orderDescribe">
            工单系统主要负责接收客户委托任务、增值服务开通审批等用途
        </p>
        <div class="orderCon">
            <router-view />
        </div>
    </div>
</template>

<script>
import Header from "@/views/Header"
export default {
    name: "Order",
    components: {
        Header
    }
}
</script>

<style lang="scss" scoped>
    p {
        margin-bottom: 0;
    }
    .orderDescribe {
        margin: 30px 80px;
        font-size: 20px;
        color: #999;
        font-weight: bold;
    }
    .orderCon {
        margin: 0 80px 0;
        padding-bottom: 30px;
        color: #333;
        font-size: 18px;
    }
</style>
