var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Header"),
      _c("p", { staticClass: "orderDescribe" }, [
        _vm._v(" 工单系统主要负责接收客户委托任务、增值服务开通审批等用途 "),
      ]),
      _c("div", { staticClass: "orderCon" }, [_c("router-view")], 1),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }